import {
  ListColumnUnifiedSmallImageMix,
  listColumnUnifiedSmallImageMixFragment,
} from '../../ContentTeaserSnippets/Templates/ListColumnUnifiedSmallImageMix.jsx';

export const headlinesListFragment = listColumnUnifiedSmallImageMixFragment;

export const HeadlinesList = ({
  className, contents, headlineTranslation, pageSectionId,
}) => (
  <ListColumnUnifiedSmallImageMix
    className={className}
    contents={contents}
    headlineTranslation={headlineTranslation}
    pageSectionId={pageSectionId}
    hideDescription
  />
);

HeadlinesList.darkStyles = ListColumnUnifiedSmallImageMix.darkStyles;
