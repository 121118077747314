import { StyledCoverImage as CoverImage } from './CoverImage.jsx';
import { StyledSlideImage as SlideImage } from './SlideImage.jsx';
import { SlideArrows } from './SlideArrows.jsx';
import { StyledControlBar as ControlBar } from './ControlBar.jsx';
import { StyledTextOverlay as TextOverlay } from './TextOverlay.jsx';
import { StyledExpandableTextOverlay as ExpandableTextOverlay } from './ExpandableTextOverlay.jsx';
import { Copyright } from './Copyright.jsx';
import { StyledIndicator as Indicator } from './Indicator.jsx';

export const Partials = {
  SlideArrows,
  ControlBar,
  TextOverlay,
  ExpandableTextOverlay,
  Copyright,
  CoverImage,
  SlideImage,
  Indicator,
};
