import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import {
  Partials, StyledContentTeaser as ContentTeaser, partialFragments, darkPartialStyles,
} from '../ContentTeaserPartials';
import { makeMemoFragment } from '../../../utils/graphql';

export const smallImageTextContentTeaserFragment = makeMemoFragment({
  name: 'SmallImageTextContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
        ... on TextualAspect {
          shortTeaser
          teaser
        }
        ...${partialFragments.imageWrapFragment.name}
        ...${partialFragments.contentStatusTeaserBadgeFragment.name}
        ...${partialFragments.teaserDataFragment.name}
        ...${partialFragments.linkedTitleFragment.name}
      }
      ${partialFragments.imageWrapFragment.fragment()}
      ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
      ${partialFragments.teaserDataFragment.fragment()}
      ${partialFragments.linkedTitleFragment.fragment()}
    `;
  },
});

export const SmallImageTextContentTeaser = ({
  content = {},
  className,
  imageFormatConfig,
  titleIsA,
  hideDescription,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    formatConfig={imageFormatConfig}
    content={content}
    hideDescription={hideDescription}
  >
    <Partials.LinkedImage>
      <div className="teaser-data-xs-sm">
        <Partials.ContentStatusTeaserBadge className='badge-xs-sm'/>
        <Partials.LinkedTitle isA={titleIsA} className='title-xs-sm'/>
      </div>
    </Partials.LinkedImage>
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge className='badge-above-md'/>
        <Partials.LinkedTitle isA={titleIsA} className='title-above-md'/>
        <Partials.LinkedDescription/>
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

SmallImageTextContentTeaser.darkStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkLinkedDescriptionStyles,
  darkPartialStyles.darkTeaserDataStyles,
);
