import { cx } from '@linaria/core';

import { StyledContentBlockList as ContentBlockList } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { darkExpandableStyles, StyledExpandable as Expandable } from '../../Expandable';
import { StyledDefaultHighlightSmallImageTextLayout as LimitedItemsLayout } from '../Layouts/DefaultHighlightSmallImageTextLayout';
import { StyledHighlightSmallImageTextLayout as UnlimitedItemsColumnLayout } from '../Layouts/HighlightSmallImageTextLayout';
import { SmallImageTextContentTeaser } from '../ContentTeasers/SmallImageTextContentTeaser.jsx';
import { squareConfigFactory } from '../../ResponsiveDwPicture/pictureConfigs';
import { isValidList } from '../../../utils/contentUtils';
import { listDefaultHighlightSmallImageTextFragment } from './ListDefaultHighlightSmallImageText.jsx';
import { darkContentBlockStyles } from '../../ContentBlocks/ContentBlock';
import { darkSmallImageContentTeaserStyles } from '../ContentTeasers/SmallImageContentTeaser.jsx';

export const expandableListDefaultHighlightSmallImageTextFragment = listDefaultHighlightSmallImageTextFragment;

const INITIAL_TEASER_AMOUNT = 6;

export const LimitedTeasers = ({ contents = [], children }) => (
  <LimitedItemsLayout>
    {children(contents)}
  </LimitedItemsLayout>
);

export const UnlimitedTeasers = ({
  contents = [], showMoreBtnTranslation, initialListCount, children,
}) => (
  <Expandable
    dataArray={contents}
    showMoreBtnTranslation={showMoreBtnTranslation}
    initialListCount={initialListCount}
  >
    {renderedContents => (
      <UnlimitedItemsColumnLayout>
        {children(renderedContents)}
      </UnlimitedItemsColumnLayout>
    )}
  </Expandable>
);
export const ExpandableListDefaultHighlightSmallImageText = ({
  contents,
  className,
  headlineTranslation,
  pageSectionId,
  initialListCount = INITIAL_TEASER_AMOUNT,
  showMoreBtnTranslation,
}) => {
  const isExpandable = (contents || []).length > initialListCount;
  const DynamicTeaserLayout = isExpandable ? UnlimitedTeasers : LimitedTeasers;
  if (!isValidList(contents)) {
    return null;
  }
  return (
    <ContentBlockList
      className={className}
      headlineIsA='h2'
      headlineTranslation={headlineTranslation}
      pageSectionId={pageSectionId}
    >
      <DynamicTeaserLayout contents={contents} showMoreBtnTranslation={showMoreBtnTranslation} initialListCount={initialListCount}>
        {renderedContents => renderedContents.map((content, i) => (
          <SmallImageTextContentTeaser
            key={content.id}
            content={content}
            imageFormatConfig={i > 0 ? squareConfigFactory() : undefined}
            titleIsA='h3'
          />
        ))}
      </DynamicTeaserLayout>
    </ContentBlockList>
  );
};

export const darkExpandableListDefaultHighlightSmallImageText = cx(
  darkContentBlockStyles,
  darkSmallImageContentTeaserStyles,
  darkExpandableStyles,
);

export const StyledExpandableListDefaultHighlightSmallImageText = ExpandableListDefaultHighlightSmallImageText;
