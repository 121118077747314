import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../../ContentBlocks/ContentBlock';
import { StyledDefaultSmallImageTextLayout as DefaultSmallImageTextLayout } from '../Layouts/DefaultSmallImageTextLayout';
import { darkHubStyles, StyledHub as Hub } from '../../ContentBlocks/Types/Hub.jsx';
import { squareConfigFactory } from '../../ResponsiveDwPicture/pictureConfigs';
import {
  SmallImageTextContentTeaser,
  smallImageTextContentTeaserFragment,
} from '../ContentTeasers/SmallImageTextContentTeaser.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const hubDefaultUnifiedSmallImageTextFragment = makeMemoFragment({
  name: 'HubDefaultUnifiedSmallImageText',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${smallImageTextContentTeaserFragment.name}
      }
      ${smallImageTextContentTeaserFragment.fragment()}
    `;
  },
});

export const HubDefaultUnifiedSmallImageText = ({
  hubContents = [],
  hubHeadlineFn,
  hubBottomElementFn,
  hideDescription,
  ...restProps
}) => (
  <ContentBlock headlineIsA="h1" {...restProps}>
    <DefaultSmallImageTextLayout>
      {hubContents.map(hubContent => (
        <Hub key={hubContent.content.id}
          headlineIsA="h2"
          headlineFn={hubHeadlineFn}
          hubContent={hubContent}
          bottomElementFn={hubBottomElementFn}
        >
          <SmallImageTextContentTeaser
            content={hubContent.content}
            imageFormatConfig={squareConfigFactory()}
            titleIsA="h3"
            hideDescription={hideDescription}
          />
        </Hub>
      ))}
    </DefaultSmallImageTextLayout>
  </ContentBlock>
);

export const darkHubDefaultUnifiedSmallImageTextStyles = cx(
  darkContentBlockStyles,
  darkHubStyles,
  SmallImageTextContentTeaser.darkStyles,
);

export const StyledHubDefaultUnifiedSmallImageText = HubDefaultUnifiedSmallImageText;
