import { useState } from 'react';
import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import classnames from 'classnames';
import { css } from '@linaria/core';
import { StyledLogo as Logo } from '../Logo';
import { navigationToggleFragment, StyledNavigationToggle as NavigationToggle } from '../navigation/NavigationToggle/NavigationToggle.jsx';
import { navigationGroupsFragment, StyledNavigationGroups as NavigationGroups } from '../navigation/NavigationGroups.jsx';
import { useScrollbarContext } from '../navigation/BodyScrollContext.jsx';
import { keyCodes } from '../../utils/keyCodes';

import { colors, mediaMin } from '../../utils/css';
import { noop } from '../../utils/commons';
import { useMobileAppContext } from '../hooks/mobileAppContext';
import { headerHeight } from './headerUtils';

import { languageSelectorFragmentFactory, StyledLanguageSelector as LanguageSelector } from '../navigation/LanguageSelector';
import { isFocusMenuShown, isMediaGroupShown } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';
import { HeaderContextStateProvider } from './HeaderStateContext';
import { useNavigationToggle } from '../hooks/useNavigationToggle';
import { StyledMediaNavigationGroup as MediaNavigationGroup } from '../navigation/MediaNavigation/MediaNavigationGroup.jsx';
import { StyledNavigationGroupSpacer as NavigationGroupSpacer } from '../navigation/NavigationGroupSpacer.jsx';
import {
  headerQuickNavigationItemsFragmentFactory,
  HeaderQuickNavigationItems,
} from './HeaderQuickNavigationItems.jsx';
import { transparentOnPrint } from '../GlobalPrintStyles';
import { useEventListener } from '../hooks/useEventListener';
import { makeHeaderBlueEvent, makeHeaderWhiteEvent } from '../hooks/useHeaderColorToggle';
import globals from '../../utils/globals';
import { limitedPageClass } from '../Page/LimitedPageLayout.jsx';
import { withTheme } from '../higherOrderComponents/withTheme.jsx';
import { useForcedTheme } from '../hooks/useForcedTheme';

const headerAlternateUrlFragment = makeMemoFragment({
  name: 'HeaderAlternateUrl',
  fragment() {
    return gql`fragment ${this.name} on UrlAspect {
      alternateUrls
    }`;
  },
});

export const headerFragmentFactory = ({ isContent = true } = {}) => makeMemoFragment(({
  name: `Header${isContent ? '' : 'OnQuery'}`,
  fragment() {
    const languageSelectorFragment = languageSelectorFragmentFactory({ isContent });
    const headerQuickNavigationFragment = headerQuickNavigationItemsFragmentFactory({ isContent });
    return gql`fragment ${this.name} on ${isContent ? 'Content' : 'Query'} {
        ... on ${isContent ? 'AssociationsAspect' : 'Query'} {
          topStoriesNavigation${isContent ? '' : '(lang: $lang)'} {
            namedUrl
            ...${navigationGroupsFragment.name}
            ...${navigationToggleFragment.name}
            ...${headerQuickNavigationFragment.name}
          }
          ...${languageSelectorFragment.name}
          ${isContent ? `...${headerAlternateUrlFragment.name}` : ''}
        }
      }
      ${navigationGroupsFragment.fragment()}
      ${navigationToggleFragment.fragment()}
      ${headerQuickNavigationFragment.fragment()}
      ${languageSelectorFragment.fragment()}
      ${isContent ? headerAlternateUrlFragment.fragment() : ''}
    `;
  },
}));

export const hiddenClassName = 'hidden';

export const Header = ({
  onlyDefaultState = false,
  className,
  content = {},
  children = noop,
}) => {
  const { topStoriesNavigation, alternateUrls } = content;
  const [
    { isNavigationVisible, isLanguageToggled },
    { openNav, closeNav, toggleLang },
  ] = useNavigationToggle();

  const [{ scrollbarWidth }] = useScrollbarContext();
  const { isMobileMode } = useMobileAppContext();
  const [shouldBeInAlternateState, setShouldBeInAlternateState] = useState(false);
  const [isForcedDarkMode] = useForcedTheme();
  const isFocusMenuVisible = isFocusMenuShown(topStoriesNavigation);
  const isMediaGroupVisible = isMediaGroupShown(topStoriesNavigation);

  const isHeaderInAlternateState = shouldBeInAlternateState && !isNavigationVisible;

  const closeNavOnEscKeyDown = event => {
    const { key } = event;
    if (key === keyCodes.ESCAPE && isNavigationVisible) {
      event.preventDefault();
      event.stopPropagation();
      closeNav();
    }
  };
  const setNavigationVisible = isVisible => (isVisible ? openNav() : closeNav());

  const learngermanUrl = topStoriesNavigation?.footer?.learngermanUrl;

  useEventListener(globals.window, makeHeaderBlueEvent, () => {
    setShouldBeInAlternateState(false);
  });
  useEventListener(globals.window, makeHeaderWhiteEvent, () => {
    if (!onlyDefaultState) {
      setShouldBeInAlternateState(true);
    }
  });

  if (isMobileMode) {
    return null;
  }

  return (
    <HeaderContextStateProvider value={{
      isNavigationVisible,
      isLanguageToggled,
      isFocusMenuVisible,
      isHeaderInAlternateState,
      isForcedDarkMode,
    }}>
      <>
        <div
          style={{ width: `calc(100% - ${scrollbarWidth})` }}
          className={classnames(
            'page-header',
            transparentOnPrint,
            limitedPageClass,
            className,
            {
              alternate: isHeaderInAlternateState,
              'focus-menu-shown': isFocusMenuVisible,
            },
          )}
          onKeyDownCapture={closeNavOnEscKeyDown}
        >
          <Logo className="logo" href={topStoriesNavigation?.namedUrl}/>
          <div className="dynamic-part">
            {children({ isHeaderInAlternateState, isForcedDarkMode })}
          </div>
          {topStoriesNavigation && (
            <NavigationToggle
              setNavigationVisible={setNavigationVisible}
              learngermanUrl={learngermanUrl}
            >
              <div className={classnames({ [hiddenClassName]: isLanguageToggled })}>
                {isMediaGroupVisible && (
                  <NavigationGroupSpacer>
                    <MediaNavigationGroup navigation={topStoriesNavigation}/>
                  </NavigationGroupSpacer>
                )}
                <NavigationGroupSpacer>
                  <NavigationGroups navsByGroup={topStoriesNavigation}/>
                </NavigationGroupSpacer>
              </div>
              {isNavigationVisible &&
                <>
                  <LanguageSelector
                    className="language-selector"
                    hiddenClassName={hiddenClassName}
                    onClick={toggleLang}
                    content={content}
                  />
                </>
              }
            </NavigationToggle>
          )}
          <HeaderQuickNavigationItems
            topStoriesNavigation={topStoriesNavigation}
            alternateUrls={alternateUrls}
          />
        </div>
        <div className='spx'></div>
      </>
    </HeaderContextStateProvider>
  );
};
// TODO linaria-next: css``
export const commonHeaderStyles = `
  --header-logo-w: 70px;
  --header-burger-w: 50px;
  --header-max-h: ${headerHeight.xs.wide}px;

  align-items: center;
  display: grid;
  grid-template-areas: "logo dynamic-part quick-navigation burger-button"
                        ". dynamic-part . ."
                        "focus-menu focus-menu focus-menu focus-menu";
  grid-template-columns: var(--header-logo-w) minmax(0,1fr) auto var(--header-burger-w);
  grid-template-rows: 50px auto;

  background-color: ${colors.DW_LIGHT_BLUE};
  min-height: ${headerHeight.xs.thin}px;

  & + .spx {
    margin-top: ${headerHeight.xs.thin}px;
  }

  &.focus-menu-shown + .spx {
    margin-top: ${headerHeight.xs.wide}px;
  }

  &::after {
    content: "";
    background-color: ${colors.DW_LIGHT_BLUE};
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    max-height: var(--header-max-h);
    opacity: 1;
    z-index: 3;
    pointer-events: none;
  }

  .focus-menu {
    grid-area: focus-menu;
    z-index: 4;
  }

  .focus-menu + * {
    grid-area: focus-menu;
    z-index: 5;
  }

  .quick-navigation-items {
    grid-area: quick-navigation;
  }

  & > .logo {
    grid-area: logo;
    justify-self: center;
    z-index: 4;
  }

  .hidden {
    display: none;
  }
`;

export const cssVariables = {
  altenateBackground: '--header-alternate-background',
};

export const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  z-index: 14;

  ${commonHeaderStyles}

  &::after {
    transition: opacity 150ms cubic-bezier(0.43, 0, 0.09, 1);
    transform: translate3d(0,0,0);
  }

  &.alternate {
    background-color: var(${cssVariables.altenateBackground}, ${colors.WHITE});
    box-shadow: 0 0 10px 0 ${colors.DARK_BLUE_GREY_01};

    &::after {
      opacity: 0;
    }
  }

  .focus-menu {
    margin-inline: 14px 15px;
  }

  .dynamic-part {
    display: flex;
    align-items: center;

    transition: opacity 50ms;
    opacity: 1;
    z-index: 1;

    grid-area: dynamic-part;
  }

  ${mediaMin.md`
    --header-logo-w: 83px;
    --header-burger-w: 70px;
    --header-max-h: ${headerHeight.md}px;
    max-height: 80vh;
    grid-template-rows: ${headerHeight.md}px;

    & + .spx, &.focus-menu-shown + .spx {
      margin-top: ${headerHeight.md}px;
    }

    .focus-menu,
    .focus-menu + * {
      grid-area: dynamic-part;
    }
  `}

  ${mediaMin.lg`
    --header-logo-w: 127px;
    --header-max-h: ${headerHeight.lg}px;
    grid-template-rows: ${headerHeight.lg}px;

    & + .spx, &.focus-menu-shown + .spx {
      margin-top: ${headerHeight.lg}px;
    }
  `}

  ${mediaMin.xl`
    --header-logo-w: 167px;
  `}
`;

StyledHeader.darkStyles = css`${cssVariables.altenateBackground}: ${colors.DARK_BLUE_GREY_01};`;

export const StyledHeaderWithTheme = withTheme(StyledHeader)(StyledHeader.darkStyles);
