import { useInView } from 'react-intersection-observer';

import { useLoadInstagramApiEffect } from '../../utils/instagram';
import globals from '../../utils/globals';
import { useHtmlLoad } from '../hooks/useHtmlLoad';
import { useInstagramEmbeddingsProcessing } from './useInstagramEmbeddingsProcessing';
import { INTERSECTION_OBSERVER_SETTINGS } from '../LazyLoadDwPicture.jsx';
import { StyledCmpPreviewCompatiblePlaceholder as CmpPreviewCompatiblePlaceholder } from '../CmpPreviewCompatiblePlaceholder.jsx';

const cmpPreviewClass = 'instagram-oembed';
// globals.window not defined during the transformation of the linaria/wyw-in-js plugin
if (globals.window) {
  // eslint-disable-next-line fp/no-mutation
  globals.window.cmp_preview_vendor_s14_class = `.${cmpPreviewClass}`;
}

export const InstagramPost = ({ url, className }) => {
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { instgrm } = useLoadInstagramApiEffect({ inView });
  const oembedUrlWhenApiAvailable = url && instgrm && `https://graph.facebook.com/v15.0/instagram_oembed?url=${url}&access_token=462865571602271|144c38399b47bc28dcf3a85f877159e2&omitscript=true`;
  const embedData = useHtmlLoad(oembedUrlWhenApiAvailable);
  useInstagramEmbeddingsProcessing(embedData && instgrm);

  return (
    <CmpPreviewCompatiblePlaceholder
      className={className}
      forwardRef={inViewRef}
    >
      <div
        className={cmpPreviewClass}
        dangerouslySetInnerHTML={embedData && { __html: embedData }}
      />
    </CmpPreviewCompatiblePlaceholder>

  );
};

export const StyledInstagramPost = InstagramPost;
