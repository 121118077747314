import { css } from '@linaria/core';
import {
  StyledTextSemiBold,
  StyledTextWideMedium,
  StyledTextWideBold,
  textColors,
  italicStylesForSystemFonts,
  italicClass,
  StyledTextWideRegular,
} from './TextBase';
import { TextGrey } from './Text.jsx';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';
import { globalCssVariables } from '../Page/PageGlobalStyles';

const informationLineHeight = css`line-height: var(${globalCssVariables.lineHeight});`;

const informationSystemFont = css`
  font-family: Arial, Helvetica, sans-serif;
  &.zh, &.zh-hant {
    font-family: 'MS Song','MS Hei', Arial, Helvetica, sans-serif;
  }
  &.bn {
    font-family: 'BN BIDISHA Opentype', Verdana, Arial, Helvetica, sans-serif;
  }
  &.hi {
    font-family: DVBOTSurekh, Mangal, Verdana, Arial, Helvetica, sans-serif;
  }
  &.ar, &.fa-af, &.fa-ir, &.ps, &.ur{
    font-family: Verdana, Arial, Helvetica, sans-serif;
  }
  &.am {
    font-family: Nyala, Arial, Helvetica, sans-serif;
  }
`;

// InformationDefault
export const InformationDefault = extendWithClassNames(TextGrey)(informationLineHeight);
InformationDefault.darkStyles = TextGrey.darkStyles;
// InformationItalic
export const InformationItalic = extendWithClassNames(InformationDefault)(italicClass);
InformationItalic.darkStyles = InformationDefault.darkStyles;

// InfoLabelWhite
export const InfoLabelWhite = extendWithClassNames(StyledTextSemiBold)(
  informationLineHeight,
  css`${textColors.WHITE_AND_WHITE.lightStyles}`,
);
InfoLabelWhite.darkStyles = textColors.WHITE_AND_WHITE.darkStyles;
// InfoLabelDark
export const InfoLabelDark = extendWithClassNames(StyledTextSemiBold)(
  informationLineHeight,
  css`${textColors.DARK_BLUE_GREY_01_AND_DARK_BLUE_GREY_01.lightStyles}`,
);
InfoLabelDark.darkStyles = textColors.DARK_BLUE_GREY_01_AND_DARK_BLUE_GREY_01.darkStyles;
// InfoEmphasized
export const InfoEmphasized = extendWithClassNames(StyledTextWideMedium)(
  informationLineHeight,
  css`${textColors.DARK_BLUE_GREY_01_AND_BLUE_GREY_02.lightStyles}`,
);
InfoEmphasized.darkStyles = textColors.DARK_BLUE_GREY_01_AND_BLUE_GREY_02.darkStyles;
// InfoError
export const InfoError = extendWithClassNames(StyledTextWideRegular)(
  italicClass,
  informationLineHeight,
  css`${textColors.BREAKING_RED_AND_ACCENT_RED.lightStyles}`,
);
InfoError.darkStyles = textColors.BREAKING_RED_AND_ACCENT_RED.darkStyles;
// InfoAds
export const InfoAds = extendWithClassNames(StyledTextWideBold)(
  informationLineHeight,
  css`${textColors.BLUE_GREY_05_AND_BLUE_GREY_02.lightStyles}`,
);
InfoAds.darkStyles = textColors.BLUE_GREY_05_AND_BLUE_GREY_02.darkStyles;
// InfoAdsRegular
export const InfoAdsRegular = extendWithClassNames(StyledTextWideRegular)(
  informationLineHeight,
  css`${textColors.BLUE_GREY_05_AND_BLUE_GREY_02.lightStyles}`,
);
InfoAdsRegular.darkStyles = textColors.BLUE_GREY_05_AND_BLUE_GREY_02.darkStyles;
// InfoSystemFont
export const InfoSystemFont = extendWithClassNames(InformationDefault)(
  informationSystemFont,
);
InfoSystemFont.darkStyles = TextGrey.darkStyles;
// InfoSystemFontItalic
export const InfoSystemFontItalic = extendWithClassNames(InformationDefault)(
  informationSystemFont,
  css`${italicStylesForSystemFonts}`,
);
InfoSystemFontItalic.darkStyles = TextGrey.darkStyles;
