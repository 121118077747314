import { css } from '@linaria/core';
import { StyledVideoJsSpinner as VideoJsSpinner } from './VideoJsSpinner.jsx';
import { StyledInfoBar as InfoBar } from '../../utils/video/components/InfoBar.jsx';
import { StyledVideoPlayButton as VideoPlayButton } from '../../utils/video/components/VideoPlayButton.jsx';
import { BrowserOnly } from '../BrowserOnly.jsx';

export const VideoJsSkeleton = ({
  content,
  showSpinner,
  showSkeleton,
  playButtonTitle,
  onPlayClick,
}) => (
  <BrowserOnly>
    {showSpinner && <VideoJsSpinner />}
    {showSkeleton && (
      <div className={videoJsSkeletonClass}>
        <VideoPlayButton buttonTitle={playButtonTitle} onClick={onPlayClick}/>
        <InfoBar videoDuration={content.formattedDuration}/>
      </div>
    )}

  </BrowserOnly>
);

export const videoJsSkeletonClass = css`
  direction: ltr;
`;
