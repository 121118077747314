import { css, cx } from '@linaria/core';
import { colors } from '../utils/css';
import { StyledButtonBase as ButtonBase } from './buttons/Button/ButtonBase.jsx';

export const Backdrop = ({
  className,
  onClick,
  show = false,
}) => (
  <ButtonBase
    className={cx(className, backdropClass, show && 'visible')}
    onClick={onClick}
    tabIndex={-1}
    aria-hidden="true"
  />
);

// @VisibleForTesting
export const backdropClass = css`
  transition: opacity 300ms cubic-bezier(0.09, 0, 0.09, 1);
  opacity: 0;

  &.visible {
    position: fixed;
    inset-inline-start: 0;
    top: 0;
    background-color: ${colors.DW_DARK_BLUE};
    opacity: .67;
    width: 100%;
    height: 100vh;
    z-index: -1;
    cursor: pointer;
    transition: opacity 500ms cubic-bezier(0.09, 0, 0.09, 1);
  }
`;
