import { css, cx } from '@linaria/core';
import { CopyrightInfo } from '../../CopyrightInfo.jsx';
import { hexToRgba, colors, mediaMin } from '../../../utils/css';

export const Copyright = ({ className, galleryImage }) => {
  const licenserSupplement = galleryImage?.assignedImage?.licenserSupplement;
  return (
    <CopyrightInfo className={cx(className, copyRightClassName, CopyrightInfo.darkStyles)} licenserSupplement={licenserSupplement}/>
  );
};

export const copyRightClassName = css`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding-inline-end: 15px;
    padding-top: 10px;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0), ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.9)} 45%);

    ${mediaMin.sm`
      padding-top: 0;
      background-image: linear-gradient(to bottom,rgba(0,0,0,0), ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.9)} 70%);
    `}
`;
