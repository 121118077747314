import { css, cx } from '@linaria/core';
import { InfoSystemFont, InfoSystemFontItalic } from './Text/Information.jsx';
import { useTranslation } from './hooks/useTranslation.jsx';

export const CopyrightInfo = ({ isA = InfoSystemFont, className, licenserSupplement }) => {
  const imageCopyrightPrefix = useTranslation('general.image.copyright_prefix');
  if (!licenserSupplement) {
    return null;
  }
  const Tag = isA;
  return (
    <Tag isA='small' className={cx(className, copyrightInfoClassName)}>
      {imageCopyrightPrefix} {licenserSupplement}
    </Tag>
  );
};
CopyrightInfo.darkStyles = InfoSystemFont.darkStyles;

export const copyrightInfoClassName = css`
  font-size: 1rem;
`;

export const ItalicCopyrightInfo = props => <CopyrightInfo {...props} isA={InfoSystemFontItalic}/>;
ItalicCopyrightInfo.darkStyles = InfoSystemFontItalic.darkStyles;
