import { useEffect } from 'react';
import globals from '../../utils/globals';
import { useForcedTheme } from './useForcedTheme';
import { useMobileAppContext } from './mobileAppContext';

export const useOSModeDetection = () => {
  const [, setIsForcedTheme] = useForcedTheme();
  const { isMobileMode } = useMobileAppContext();

  useEffect(() => {
    if (!isMobileMode) {
      const mediaQueryList = globals.window.matchMedia('(prefers-color-scheme: dark)');
      const onChangeHandler = () => setIsForcedTheme(mediaQueryList.matches);

      onChangeHandler();
      mediaQueryList.addEventListener('change', onChangeHandler);

      return () => {
        mediaQueryList.removeEventListener('change', onChangeHandler);
      };
    }

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
