import { useEffect, useRef } from 'react';
import { css, cx } from '@linaria/core';
import { colors, mediaMin } from '../../utils/css';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { Backdrop } from '../Backdrop.jsx';
import { useScrollbarContext } from '../navigation/BodyScrollContext.jsx';
import { StyledClose as CloseIcon } from '../icons';
import { noop } from '../../utils/commons';
import { isBackwardTab, isForwardTab } from '../../utils/keyCodes';
import { getLastBtnInFeedbackSidebar, getXCloseFeedbackBtn } from './utils';
import { HeadlineMediumBlueGrey04AndDwLightBlueNew as HeadlineMediumBlueGrey04 } from '../Text/Headline.jsx';
import { ButtonWithIconOnly } from '../buttons/Button';
import { globalCssVariables } from '../Page/PageGlobalStyles/PageGlobalStyles.jsx';

export const focusXCloseFeedbackBtnOnForwardTab = event => {
  if (isForwardTab(event)) {
    event.preventDefault();
    getXCloseFeedbackBtn().focus();
  }
};

const focusLastBtnInFormOnBackwardTab = event => {
  if (isBackwardTab(event)) {
    event.preventDefault();
    getLastBtnInFeedbackSidebar().focus();
  }
};

export const FeedbackSidebar = ({
  className,
  children,
  isFeedbackOpen = false,
  onCloseFeedback = noop,
}) => {
  const feedbackHeadline = useTranslation('component.feedback_form.layer.headline');
  const closeBtnTitle = useTranslation('component.feedback_form.layer.close_button.label');
  const feedbackSidebarRef = useRef(null);

  const [, { toggleScrollLockAndSetWidth }] = useScrollbarContext();

  useEffect(() => {
    toggleScrollLockAndSetWidth({ isScrollDisabled: isFeedbackOpen, ref: feedbackSidebarRef.current });
  }, [toggleScrollLockAndSetWidth, isFeedbackOpen]);

  return (
    <>
      <div className={cx('feedback-sidebar', feedbackSidebarClass, className, isFeedbackOpen && 'opened')}
        ref={feedbackSidebarRef}
        aria-hidden={!isFeedbackOpen}
        tabIndex={-1}
      >
        <div className="header">
          <HeadlineMediumBlueGrey04 className="headline">{feedbackHeadline}</HeadlineMediumBlueGrey04>
          <ButtonWithIconOnly
            className="close-feedback-btn"
            title={closeBtnTitle}
            onClick={onCloseFeedback}
            onKeyDown={focusLastBtnInFormOnBackwardTab}
            tabIndex={isFeedbackOpen ? 0 : -1}
          >
            <CloseIcon/>
          </ButtonWithIconOnly>
        </div>
        <div className="feedback-elements">
          {children}
        </div>
      </div>
      <Backdrop
        className={cx('backdrop', feedbackBackdropClass, className)}
        show={isFeedbackOpen}
        onClick={onCloseFeedback}
      />
    </>
  );
};

export const cssVariables = {
  backgroundColor: '--feedback-sidebar-background-color',
  headerBackgroundColor: '--feedback-sidebar-header-background-color',
};

export const feedbackSidebarClass = css`
  &.feedback-sidebar {
    position: fixed;
    z-index: -10;
    background-color: var(${cssVariables.backgroundColor}, ${colors.WHITE});
    inset-inline-end: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transform: translateX(calc(var(${globalCssVariables.flowDirection}) * 100%));
    transition: transform 300ms cubic-bezier(0.43,0,0.09,1) 0s, z-index 0ms 300ms;
    outline: none;
  }

  &.feedback-sidebar.opened {
    z-index: 11;
    transform: translateX(0);
    transition: transform 500ms cubic-bezier(0.43,0,0.09,1) 0s;
  }

  .header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(${cssVariables.headerBackgroundColor}, ${colors.BLUE_GREY_01});
    padding-block: 10px;
    padding-inline: 15px 8px;
    z-index: 1;
  }

  .headline {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 1.6rem;
    letter-spacing: 0.12rem;
  }

  ${mediaMin.sm`
    .header {
      padding-inline-start: 45px;
    }
  `}

    ${mediaMin.md`
    &.feedback-sidebar {
      width: 600px;
    }

    .header {
      padding-inline-end: 14px;
      padding-block: 15px;
    }
  `}

  ${mediaMin.lg`
    .header {
      padding-block: 20px;
    }
  `}
`;

export const feedbackBackdropClass = css`
  &.backdrop{
    z-index: 10;
  }
`;

FeedbackSidebar.darkStyles = cx(
  HeadlineMediumBlueGrey04.darkStyles,
  ButtonWithIconOnly.darkStyles,
  css`
    ${cssVariables.backgroundColor}: ${colors.DARK_BLUE_GREY_01};
    ${cssVariables.headerBackgroundColor}: ${colors.DARK_BLUE_GREY_02};
  `,
);
