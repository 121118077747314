import { useEffect, useRef } from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import {
  colors, mediaDevice,
  mediaMax, mediaMin,
} from '../../../utils/css';
import { StyledBurger as BurgerIcon } from '../../icons';
import { noop } from '../../../utils/commons';
import { useScrollbarContext } from '../BodyScrollContext.jsx';
import { Backdrop } from '../../Backdrop.jsx';
import { ButtonWithIconOnly } from '../../buttons/Button';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { learnGermanFragment, StyledLearnGerman as LearnGerman } from '../LearnGerman.jsx';
import { getOnlyVisibleLearnGermanLink, isSameNode } from '../utils';
import { isBackwardTab, isForwardTab } from '../../../utils/keyCodes';
import { headerHeight } from '../../Header/headerUtils';
import { focusElementById } from '../../../utils/metaDataHelpers';
import { useHeaderStateContext } from '../../Header/HeaderStateContext';
import { ids } from '../../../utils/staticElementIds';

export const navigationToggleFragment = learnGermanFragment;

const focusLastNavigationItemFromBurgerButtonOnBackwardTab = (event, isNavigationVisible) => {
  if (isNavigationVisible && isBackwardTab(event)) {
    event.preventDefault();
    getOnlyVisibleLearnGermanLink().focus();
  }
};

const focusBurgerButtonFromLastNavigationItemOnForwardTab = event => {
  if (isForwardTab(event) && isSameNode({ event, node: getOnlyVisibleLearnGermanLink() })) {
    event.preventDefault();
    focusElementById(ids.quickNav.navigationToggle);
  }
};

export const NavigationToggle = ({
  className,
  children,
  setNavigationVisible = noop,
  learngermanUrl,
}) => {
  const {
    isForcedDarkMode, isHeaderInAlternateState, isNavigationVisible, isLanguageToggled, isFocusMenuVisible,
  } = useHeaderStateContext();

  const navElements = useRef(null);
  const buttonTitle = useTranslation(`component.navigation.toggle_button.label.${isNavigationVisible ? 'close' : 'open'}`);
  const [, { toggleScrollLockAndSetWidth }] = useScrollbarContext({
    onReset: () => toggleVisibilityOfNavigation({ isNavigationVisible: false }),
  });

  const toggleVisibilityOfNavigation = ({ isNavigationVisible: isNavVisible }) => {
    toggleScrollLockAndSetWidth({ isScrollDisabled: isNavVisible, ref: navElements.current });
    setNavigationVisible(isNavVisible);
  };

  useEffect(() => {
    if (navElements.current) {
      // eslint-disable-next-line fp/no-mutation
      navElements.current.scrollTop = 0;
    }
  }, [isNavigationVisible, isLanguageToggled]);
  return (
    <nav className={className}>
      <ButtonWithIconOnly
        id={ids.quickNav.navigationToggle}
        className={cx(
          'nav-btn',
          isNavigationVisible && 'visible',
          isForcedDarkMode && isHeaderInAlternateState && ButtonWithIconOnly.darkStyles,
        )}
        title={buttonTitle}
        aria-expanded={isNavigationVisible}
        onClick={() => {
          toggleVisibilityOfNavigation({ isNavigationVisible: !isNavigationVisible });
        }}
        onKeyDown={event => {
          focusLastNavigationItemFromBurgerButtonOnBackwardTab(event, isNavigationVisible);
        }}
      >
        <BurgerIcon className="burger-icon"/>
      </ButtonWithIconOnly>
      <div className="nav-position">
        <div
          className={cx(
            'nav-height',
            isFocusMenuVisible && 'focus-menu-inline',
            isLanguageToggled && 'language-open',
          )}
          role="button"
          tabIndex="-1"
          onKeyDown={focusBurgerButtonFromLastNavigationItemOnForwardTab}
        >
          <div className="nav-background">
            <div className="nav-elements" ref={navElements}>
              {children}
              {isNavigationVisible && (
                <LearnGerman
                  className="learn-german-link"
                  learngermanUrl={learngermanUrl}
                />
              )}
            </div>
          </div>
          {isNavigationVisible && (
            <LearnGerman
              className="learn-german-link"
              learngermanUrl={learngermanUrl}
            />
          )}
        </div>
      </div>
      <Backdrop
        className="backdrop"
        show={isNavigationVisible}
        onClick={() => {
          toggleVisibilityOfNavigation({ isNavigationVisible: !isNavigationVisible });
        }}
      />
    </nav>
  );
};
// TODO linaria-next: css``
export const commonNavigationToggleStyles = `
  .nav-btn {
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    margin: 5px;
    z-index: 5;
    width: 40px;

    .burger-icon {
      width: 20px;
    }
  }

  .nav-height > .learn-german-link {
    display: none;
  }

  .nav-position {
    background-color: ${colors.DW_LIGHT_BLUE};
    width: 100%;
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    overflow: auto;
    max-height: 100vh;
  }

  .nav-height {
    padding-top: ${headerHeight.xs.thin}px;
    outline: none;
    cursor: unset;
  }

  .nav-elements {
    position: relative;
    height: calc(100svh - ${headerHeight.xs.thin}px);
    overflow-y: auto;
    overflow-x: hidden;
    visibility: hidden;
  }

  .language-open {
    .nav-background {
      background-color: ${colors.DARK_BLUE_GREY_01};
    }
  }

  ${mediaMax.xs`
    .nav-height {
      &.focus-menu-inline {
        &.language-open {
          padding-top: calc(${headerHeight.xs.wide}px + 95px);
        }
      }

      &.language-open {
        padding-top: calc(${headerHeight.xs.thin}px + 95px);
      }
    }
  `}

  ${mediaMax.sm`
    .nav-height {
      &.focus-menu-inline {
        padding-top: ${headerHeight.xs.wide}px;

        .nav-elements {
          height: calc(100svh - ${headerHeight.xs.wide}px);
        }

        &.language-open {
          padding-top: calc(${headerHeight.xs.wide}px + 99px);

          .nav-elements {
            height: calc(100svh - (${headerHeight.xs.wide}px + 99px));
          }
        }
      }

      &.language-open {
        padding-top: calc(${headerHeight.xs.thin}px + 99px);

        .nav-elements {
          height: calc(100svh - (${headerHeight.xs.thin}px + 99px));
        }
      }
    }
  `}

  ${mediaMin.md`
    .nav-btn {
      margin: 10px;
    }

    .nav-height {
      max-height: 80vh;
      padding-top: ${headerHeight.md}px;

      &.language-open {
        padding-top: calc(${headerHeight.md}px + 109px);

        .nav-elements {
          max-height: calc(80vh - (136px + 109px));
        }
      }
    }

    .nav-elements {
      max-height: calc(80vh - 136px);
      height: 100%;
    }

    .nav-height > .learn-german-link {
      display: block;
    }
    .nav-elements > .learn-german-link {
      display: none;
    }
  `}
`;

export const StyledNavigationToggle = styled(NavigationToggle)`
  ${commonNavigationToggleStyles}
  .nav-btn {
    .burger-icon {
      path {
        stroke: none;
      }
      g {
        transform: rotate(0);
        transform-origin: 50%;
        transition: transform 300ms cubic-bezier(0.91, 0, 0.57, 1);
      }

      .top-line path,
      .bottom-line path {
        transform: translateY(0);
        transition: transform 100ms cubic-bezier(0.43, 0, 0.09, 1) 200ms;
      }
    }
  }
  .nav-btn.visible .burger-icon {
    g {
      transform: rotate(-225deg);
      transition: transform 500ms cubic-bezier(0.43, 0, 0.09, 1);
    }

    path {
      transition: transform 167ms cubic-bezier(0.43, 0, 0.09, 1);
    }

    .top-line path {
      transform: translateY(7px);
    }

    .bottom-line {
      transform: rotate(-135deg);

      path {
        transform: translateY(-7px);
      }
    }
  }

  .nav-position {
    transition: transform 300ms cubic-bezier(0.43, 0, 0.09, 1);
    transform: translateY(-100%);
  }

  .nav-btn.visible ~ .nav-position {
    transition: transform 500ms cubic-bezier(0.43, 0, 0.09, 1);
    transform: translateY(0);

    .nav-elements {
      visibility: visible;
    }
  }

  .nav-elements {
    scrollbar-width: thin;
    scrollbar-color: ${colors.DW_DARK_BLUE} transparent;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.DW_DARK_BLUE};
      border-radius: 1px;
      background-clip: content-box;
      border: 2px solid transparent;

      ${mediaDevice.mouse`
        :hover {
          border-radius: 4px;
          background-color: ${colors.BLACK};
          border-color: ${colors.BLACK};
        }
      `}
    }

    &::-webkit-scrollbar-button {
      display: block;
      background-color: transparent;
    }
    &::-webkit-scrollbar-button:vertical:start {
      height: 8px;
    }
    &::-webkit-scrollbar-button:vertical:end {
      height: 8px;
    }
  }

  .language-open {
    .nav-elements {
      scrollbar-color: ${colors.WHITE} transparent;

      &::-webkit-scrollbar-thumb {
        background-color: ${colors.WHITE};

        ${mediaDevice.mouse`
          :hover {
            background-color: ${colors.BLUE_GREY_01};
            border-color: ${colors.BLUE_GREY_01};
          }
        `}
      }
    }
  }

  ${mediaMin.md`
    .nav-elements {
      margin-inline-end: 13px;
    }
  `}

  ${mediaMin.lg`
    .nav-btn {
      margin: 15px;
    }

    .nav-height {
      padding-top: ${headerHeight.lg}px;

      &.language-open {
        padding-top: calc(${headerHeight.lg}px + 109px);

        .nav-elements {
          max-height: calc(80vh - (156px + 109px));
        }
      }
    }
    .nav-elements {
      max-height: calc(80vh - 156px);
      margin-inline-end: 28px;
    }
  `}
`;
