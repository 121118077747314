import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { makeMemoFragment } from '../../../utils/graphql';
import {
  darkSmallImageMixContentTeaserStyles,
  StyledSmallImageMixContentTeaser as SmallImageMixContentTeaser,
  smallImageMixContentTeaserFragment,
} from '../ContentTeasers/SmallImageMixContentTeaser.jsx';
import { StyledColumnSmallImageMixLayout as ColumnSmallImageMixLayout } from '../Layouts/ColumnSmallImageMixLayout';
import { darkContentBlockListStyles, StyledContentBlockList as ContentBlockList } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { isValidList } from '../../../utils/contentUtils';

export const listColumnUnifiedSmallImageMixFragment = makeMemoFragment({
  name: 'listColumnUnifiedSmallImageMix',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${smallImageMixContentTeaserFragment.name}
      }
      ${smallImageMixContentTeaserFragment.fragment()}
    `;
  },
});

export const ListColumnUnifiedSmallImageMix = ({
  className, contents, headlineTranslation, pageSectionId, hideDescription,
}) => {
  if (!isValidList(contents)) {
    return null;
  }

  return (
    <ContentBlockList
      className={className}
      headlineIsA="h2"
      headlineTranslation={headlineTranslation}
      pageSectionId={pageSectionId}
    >
      <ColumnSmallImageMixLayout>
        {contents.map(content => (
          <SmallImageMixContentTeaser
            key={content.id}
            content={content}
            hideDescription={hideDescription}
            titleIsA="h3"
          />
        ))}
      </ColumnSmallImageMixLayout>
    </ContentBlockList>
  );
};

ListColumnUnifiedSmallImageMix.darkStyles = cx(
  darkContentBlockListStyles,
  darkSmallImageMixContentTeaserStyles,
);
